import React ,{ useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarConsumer } from '../snackbarContext';

import axios from 'axios';

const Video = ({ camera, showBoxesAroundObjects, showVideoSectionOnly, showvideosection, showMaxResolution, maxResolution }) => {
    const HOST = global.config.API;
    const PROXY = global.config.PROXY;
    const [showBoxes, setShowBoxes] = useState(showBoxesAroundObjects);
    const [isShown, setIsShown] = useState(false); // Hover to show green frame
    const [imageError, setImageError] = useState(false);

    // Function to securely format URLs
    const secureUrl = (url) => {
        if( HOST.startsWith('http://')){
            return url;
        }    
        // If URL starts with http://, rewrite it to use your secure proxy
        if (url.startsWith('http://')) {
            return `${PROXY}${url}`;
        } else if (url.startsWith('https://')) {
            return url;
        } else {
            // If URL does not start with http:// or https://, assume it is a relative URL
            return `${PROXY}http://${url}`;
        }
    };

    const useStyles = makeStyles({
        root: {
            '-webkit-user-select': 'none',
            display: 'block',
            width: '100%',
            'min-width': '200px',
            height: 'auto',
            'padding-top': '5px',
            'padding-bottom': '5px',
        },
    });
    const classes = useStyles();

    const scrollToCamera = () => {
        const stream = document.getElementById(`stream${camera.cam}`);
        const div = document.getElementById(camera.cam);
        stream.scrollIntoView({ behavior: 'smooth' });
        stream.focus();
        div.style.boxShadow = "0 0 10px 5px green";
    };

    const activateCamera = (handleOpen) => {
        const URL = HOST + "urls";
        const payload = {
            id: camera.id,
            url: camera.url,
            objects_counted: 0 // activate this camera
        };
        axios.put(URL, payload)
            .then(function (response) {
                console.log(response);
                handleOpen(response.data.message, 'success');
            })
            .catch(function (error) {
                console.log(error);
                handleOpen(error.response.data.message, 'error');
            });
    };

    const menu = (showvideosection, maxResolution) => (
        <nav className="menu">
            <input type="checkbox" href="#" className="menu-open" name={`menu-open-${camera.cam}`} id={`menu-open-${camera.cam}`} />
            <label className="menu-open-button" htmlFor={`menu-open-${camera.cam}`}>
                <span className="hamburger hamburger-1"></span>
                <span className="hamburger hamburger-2"></span>
                <span className="hamburger hamburger-3"></span>
                <span className="hamburger hamburger-4"></span>
            </label>

            <span className="menu-item" onClick={() => { showMaxResolution(); }}>
                <i className={!maxResolution ? "fa fa-expand" : "fa fa-toggle-down"}></i>
            </span>
            {camera.objects_counted < 0 ?
                <SnackbarConsumer>
                    {({ handleOpen }) => (
                        <span className="menu-item" onClick={() => { activateCamera(handleOpen); }}>
                            <i className="fa fa-camera"></i>
                        </span>
                    )}
                </SnackbarConsumer>
                :
                <span className="menu-item" onClick={() => { showVideoSectionOnly(); scrollToCamera(); }}>
                    <i className={!showvideosection ? "fa fa-bar-chart" : "fa fa-play"}></i>
                </span>
            }
            <SnackbarConsumer>
                {({ handleMapPoint }) => (
                    <span className="menu-item" onClick={() => { handleMapPoint([camera.lat, camera.lng]); }}>
                        <i className="fa fa-map"></i>
                    </span>
                )}
            </SnackbarConsumer>
        </nav>
    );

    const well = isShown ? {
        boxShadow: "0 0 10px 5px green"
    } : {};
    const header = (camera.city ? camera.city + ',' : '') + (camera.region ? camera.region + ',' : '') + (camera.country ? camera.country : '');

    useEffect(() => {
        setShowBoxes(showBoxes);
    }, [showBoxes]);

    if (imageError) {
        return null; // Do not render the component if the image failed to load
    }

    return (
        <div id={camera.cam} style={well}
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}>
            {menu(showvideosection, maxResolution)}

            <p>{header}</p>
            <img id={'stream' + camera.cam} className={classes.root} alt={header}
                src={showBoxes ? HOST + "video_feed?cam=" + camera.id : secureUrl(camera.url)}
                onError={() => setImageError(true)} />
        </div>
    );
};

export default Video;