import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
// import { groupBy } from 'lodash';

import { SnackbarProvider } from './snackbarContext';

import Popup from './components/pop-up';
import URLlist from './components/urls';
import InputURL from './components/input-forms';
import VideoStreamers from './components/video-streamers';

import { Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import MarkersMap from './components/map';
import countries from './countries';

import t from './translator';

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  container: {
    padding: '1rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem',
    },
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  mapContainer: {
    width: '100%',
    height: '50vh',
    [theme.breakpoints.down('sm')]: {
      height: '40vh',
    },
  },
  popupContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  popupContent: {
    backgroundColor: 'white',
    padding: '1rem',
    borderRadius: '8px',
    maxWidth: '90%',
  },
}));

const App = (props) => {
  const classes = useStyles();

  const [state, setState] = useState({
    videoalignment: 'video',
    open: false,
    message: '',
    variant: 'success',
  });

  const [showPopup, setShowPopup] = useState(false);
  const [initialUrls, setInitialUrls] = useState([]);
  const [urls, setUrls] = useState([]);
  const [videoAlignment] = useState('video');
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [variant, setVariant] = useState('success');

  const [zoom, setZoom] = useState(8);
  const [position, setPosition] = useState([55.75, 37.57]);

  const [countryFilter, setcountryFilter] = useState('RU');
  const [cityFilter, setcityFilter] = useState('none');
  const [interestFilter, setinterestFilter] = useState('rating');
  const isVideoAndStatistic = videoAlignment === 'statistic';
  const [groupedUrls, setGroupedUrls] = useState({});
  const [cityUrls, setCityUrls] = useState({});
  const [descUrls, setDescUrls] = useState({});

  const snackbarRef = useRef(null);

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleOpen = (message, variant) => {
    setMessage(message);
    setVariant(variant);
    setOpen(true);
  };

  const rated = initialUrls.filter((url) => url.objects_counted >= 0);

  const handleCountryFilterChange = (event) => {
    if (countryFilter === 'none' && interestFilter === 'none') return;
    const selectedCountry = event.target.value;
    if (interestFilter === 'rating') {
      setUrls(
        countryFilter === 'none'
          ? rated
          : groupedUrls[selectedCountry].filter((url) => url.objects_counted >= 0)
      );
    } else {
      setUrls(
        selectedCountry === 'none'
          ? descUrls[interestFilter]
          : interestFilter === 'none'
          ? groupedUrls[selectedCountry]
          : groupedUrls[selectedCountry].filter((item) => item.desc === interestFilter)
      );
    }
    setcityFilter('none');
    setcountryFilter(selectedCountry);
    setZoom(6);
    setPosition(countries.find((c) => c.cc === selectedCountry).position);
  };

  const handleCityFilterChange = (event) => {
    if (countryFilter === 'none' && cityFilter === 'none') return;
    const selectedCity = event.target.value;
    setUrls(selectedCity === 'none' ? groupedUrls[countryFilter] : cityUrls[selectedCity]);
    setcityFilter(selectedCity);
  };

  const handleInterestFilterChange = (event) => {
    if (countryFilter === 'none' && interestFilter === 'none') return;
    const selectedInterest = event.target.value;
    if (selectedInterest === 'rating') {
      setUrls(countryFilter === 'none' ? rated : rated.filter((item) => item.country === countryFilter));
    } else {
      setUrls(
        countryFilter === 'none'
          ? descUrls[selectedInterest]
          : selectedInterest === 'none'
          ? groupedUrls[countryFilter]
          : groupedUrls[countryFilter].filter((item) => item.desc === selectedInterest)
      );
    }
    setcityFilter('none');
    setinterestFilter(selectedInterest);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMapPoint = (position) => {
    setPosition(position);
    setZoom(12);
    window.scrollTo(0, 0);
    console.log(position);
  };

  const updateurls = (initialUrls) => {
    setUrls(
      countryFilter === 'all'
        ? initialUrls
        : initialUrls.filter((url) => url.country === countryFilter)
    );
  };

  const updateparams = (param) => {
    setState({ ...state, ...param });
  };

  const loadData = () => {
    const DEFAULT_QUERY = global.config.API + 'urls?list=true';

    setLoading(true);

    axios
      .get(DEFAULT_QUERY)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          return response.data;
        } else {
          console.log('error:');
          throw new Error('Something went wrong ...');
        }
      })
      .then((data) => {
        setLoading(false);
        setInitialUrls(data);
        updateurls(data);
        setGroupedUrls(groupUrlsByCountry(data));
        setCityUrls(groupUrlsByCity(data));
        setDescUrls(groupUrlsByDesc(data));
      })
      .catch((error) => setLoading(false));
  };

  const groupUrlsByCountry = (urls) => {
    return urls.reduce((acc, cur) => {
      if (cur.country in acc) {
        acc[cur.country].push(cur);
      } else {
        acc[cur.country] = [cur];
      }
      return acc;
    }, {});
  };

  const groupUrlsByCity = (urls) => {
    return urls.reduce((acc, cur) => {
      if (cur.city in acc) {
        acc[cur.city].push(cur);
      } else {
        acc[cur.city] = [cur];
      }
      return acc;
    }, {});
  };

  const groupUrlsByDesc = (urls) => {
    return urls.reduce((acc, cur) => {
      if (cur.desc === null || cur.desc === 'null') return acc;
      if (cur.desc in acc) {
        acc[cur.desc].push(cur);
      } else {
        acc[cur.desc] = [cur];
      }
      return acc;
    }, {});
  };

  useEffect(() => {
    console.log(props.req);
    loadData();
  }, []);

  return (
    <div className="App">
      <div>
        <a href="#" onClick={handleOpenPopup}>
          {t('show_statistic').__html}
        </a>
        {showPopup && (
          <div className={classes.popupContainer}>
            <div className={classes.popupContent}>
              <Popup onClose={handleClosePopup} />
            </div>
          </div>
        )}
      </div>
      <SnackbarProvider value={{ handleOpen, handleClose, handleMapPoint }}>
        <header className={`App-header ${classes.container}`}>
          <h4>{t('welcome').__html}</h4>

          {!isLoading && (
            <div className={classes.filterContainer}>
              <label htmlFor="interestFilter">
                {t('filter_class').__html}&nbsp;
              </label>
              <select id="interestFilter" value={interestFilter} onChange={handleInterestFilterChange}>
                <option key="none" value="none"></option>
                <option key="rating" value="rating">
                  Rated
                </option>
                {Object.keys(descUrls)
                  .sort()
                  .map((desc) => (
                    <option key={desc} value={desc}>
                      {desc}
                    </option>
                  ))}
              </select>
              <label htmlFor="countryFilter">
                {t('filter_country').__html}&nbsp;
              </label>
              <select id="countryFilter" value={countryFilter} onChange={handleCountryFilterChange}>
                <option key="none" value="none"></option>
                {Object.keys(groupedUrls)
                  .sort()
                  .map((countryCode) => {
                    const country = countries.find((c) => c.cc === countryCode);
                    const countryName = country ? country.name : countryCode;
                    return (
                      <option key={countryCode} value={countryCode}>
                        {countryName}
                      </option>
                    );
                  })}
              </select>
              <label htmlFor="cityFilter">
                {t('filter_city').__html}&nbsp;
              </label>
              <select
                key={handleCountryFilterChange}
                id="cityFilter"
                value={cityFilter}
                onChange={handleCityFilterChange}
              >
                <option key="none" value="none"></option>
                {[
                  ...new Set(groupedUrls[countryFilter]?.map((url) => url.city || '')),
                ]
                  .filter((city) => city !== '')
                  .sort()
                  .map((city) => (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  ))}
              </select>
            </div>
          )}
          {!isLoading && (
            <MarkersMap
              className={classes.mapContainer}
              markers={urls}
              zoom={zoom}
              center={position}
              key={JSON.stringify(position)}
            />
          )}
          <div className="container">
            <div className="row nav-wrapper" />
            <div className="col-md-12">
              <Snackbar
                open={open}
                message={message}
                variant={variant}
                onClose={handleClose}
                autoHideDuration={6000}
                ref={snackbarRef}
                className={classes[variant]}
              />

              <h4 dangerouslySetInnerHTML={t('site_desc')} />

              <InputURL updateparams={updateparams} />
              {isVideoAndStatistic && (
                <URLlist updateparams={updateparams} updateurls={updateurls} data={urls} />
              )}
            </div>
          </div>
        </header>

        <VideoStreamers param={state} urls={urls} />

        <div className="feature-bg">
          <div className="row">
            <div className="col-md-12 nopadding">
              <div
                className="features-slider"
                dangerouslySetInnerHTML={t('features')}
              ></div>
            </div>
          </div>
        </div>
      </SnackbarProvider>
    </div>
  );
};

export default App;