const countries = [
  { cc: 'AF', name: 'Afghanistan', position: [33.93911, 67.709953] },
  { cc: 'AL', name: 'Albania', position: [41.153332, 20.168331] },
  { cc: 'DZ', name: 'Algeria', position: [28.033886, 1.659626] },
  { cc: 'AD', name: 'Andorra', position: [42.506317, 1.521835] },
  { cc: 'AO', name: 'Angola', position: [-11.202692, 17.873887] },
  { cc: 'AG', name: 'Antigua and Barbuda', position: [17.060816, -61.796428] },
  { cc: 'AR', name: 'Argentina', position: [-38.416097, -63.616672] },
  { cc: 'AM', name: 'Armenia', position: [40.069099, 45.038189] },
  { cc: 'AU', name: 'Australia', position: [-25.274398, 133.775136] },
  { cc: 'AT', name: 'Austria', position: [47.516231, 14.550072] },
  { cc: 'AZ', name: 'Azerbaijan', position: [40.143105, 47.576927] },
  { cc: 'BS', name: 'Bahamas', position: [25.03428, -77.39628] },
  { cc: 'BH', name: 'Bahrain', position: [25.930414, 50.637772] },
  { cc: 'BD', name: 'Bangladesh', position: [23.684994, 90.356331] },
  { cc: 'BB', name: 'Barbados', position: [13.193887, -59.543198] },
  { cc: 'BY', name: 'Belarus', position: [53.709807, 27.953389] },
  { cc: 'BE', name: 'Belgium', position: [50.503887, 4.469936] },
  { cc: 'BZ', name: 'Belize', position: [17.189877, -88.49765] },
  { cc: 'BJ', name: 'Benin', position: [9.30769, 2.315834] },
  { cc: 'BT', name: 'Bhutan', position: [27.514162, 90.433601] },
  { cc: 'BO', name: 'Bolivia', position: [-16.290154, -63.588653] },
  { cc: 'BA', name: 'Bosnia and Herzegovina', position: [43.915886, 17.679076] },
  { cc: 'BW', name: 'Botswana', position: [-22.328474, 24.684866] },
  { cc: 'BR', name: 'Brazil', position: [-14.235004, -51.92528] },
  { cc: 'BN', name: 'Brunei', position: [4.535277, 114.727669] },
  { cc: 'BG', name: 'Bulgaria', position: [42.733883, 25.48583]},

  { cc: 'BF', name: 'Burkina Faso', position: [12.238333, -1.561593] },
  { cc: 'BI', name: 'Burundi', position: [-3.373056, 29.918886] },
  { cc: 'CV', name: 'Cabo Verde', position: [16.5388, -23.0418] },
  { cc: 'KH', name: 'Cambodia', position: [12.565679, 104.990963] },
  { cc: 'CM', name: 'Cameroon', position: [7.369722, 12.354722] },
  { cc: 'CA', name: 'Canada', position: [56.130366, -106.346771] },
  { cc: 'CF', name: 'Central African Republic', position: [6.611111, 20.939444] },
  { cc: 'TD', name: 'Chad', position: [15.454166, 18.732207] },
  { cc: 'CL', name: 'Chile', position: [-35.675147, -71.542969] },
  { cc: 'CN', name: 'China', position: [35.86166, 104.195397] },
  { cc: 'CO', name: 'Colombia', position: [4.570868, -74.297333] },
  { cc: 'KM', name: 'Comoros', position: [-11.875001, 43.872219] },
  { cc: 'CG', name: 'Congo', position: [-0.228021, 15.827659] },
  { cc: 'CD', name: 'Democratic Republic of the Congo', position: [-4.038333, 21.758664] },
  { cc: 'CR', name: 'Costa Rica', position: [9.748917, -83.753428] },
  { cc: 'HR', name: 'Croatia', position: [45.1, 15.2] },
  { cc: 'CU', name: 'Cuba', position: [21.521757, -77.781167] },
  { cc: 'CY', name: 'Cyprus', position: [35.126413, 33.429859] },
  { cc: 'CZ', name: 'Czech Republic', position: [49.817492, 15.472962] },
  { cc: 'DK', name: 'Denmark', position: [56.26392, 9.501785] },
  { cc: 'DJ', name: 'Djibouti', position: [11.825138, 42.590275] },
  { cc: 'DM', name: 'Dominica', position: [15.414999, -61.370976] },
  { cc: 'DO', name: 'Dominican Republic', position: [18.735693, -70.162651] },
  { cc: 'EC', name: 'Ecuador', position: [-1.831239, -78.183406] },
  { cc: 'EG', name: 'Egypt', position: [26.820553, 30.802498] },
  { cc: 'SV', name: 'El Salvador', position: [13.794185, -88.89653]},
  { cc: 'GQ', name: 'Equatorial Guinea', position: [1.650801, 10.267895] },
  { cc: 'ER', name: 'Eritrea', position: [15.179384, 39.782334] },
  { cc: 'EE', name: 'Estonia', position: [58.595272, 25.013607] },
  { cc: 'ET', name: 'Ethiopia', position: [9.145, 40.489673] },
  { cc: 'FJ', name: 'Fiji', position: [-16.578193, 179.414413] },
  { cc: 'FI', name: 'Finland', position: [61.92411, 25.748151] },
  { cc: 'FR', name: 'France', position: [46.227638, 2.213749] },
  { cc: 'GA', name: 'Gabon', position: [-0.803689, 11.609444] },
  { cc: 'GM', name: 'Gambia', position: [13.443182, -15.310139] },
  { cc: 'GE', name: 'Georgia', position: [42.315407, 43.356892] },
  { cc: 'DE', name: 'Germany', position: [51.165691, 10.451526] },
  { cc: 'GH', name: 'Ghana', position: [7.946527, -1.023194] },
  { cc: 'GR', name: 'Greece', position: [39.074208, 21.824312] },
  { cc: 'GD', name: 'Grenada', position: [12.1165, -61.678999] },
  { cc: 'GT', name: 'Guatemala', position: [15.783471, -90.230759] },
  { cc: 'GN', name: 'Guinea', position: [9.945587, -9.696645] },
  { cc: 'GW', name: 'Guinea-Bissau', position: [11.803749, -15.180413] },
  { cc: 'GY', name: 'Guyana', position: [4.860416, -58.93018] },
  { cc: 'HT', name: 'Haiti', position: [18.971187, -72.285215] },
  { cc: 'HN', name: 'Honduras', position: [15.199999, -86.241905] },
  { cc: 'HU', name: 'Hungary', position: [47.162494, 19.503304] },
  { cc: 'IS', name: 'Iceland', position: [64.963051, -19.020835] },
  { cc: 'IN', name: 'India', position: [20.593684, 78.96288] },
  { cc: 'ID', name: 'Indonesia', position: [-0.789275, 113.921327] },
  { cc: 'IR', name: 'Iran', position: [32.427908, 53.688046] },
  { cc: 'IQ', name: 'Iraq', position: [33.223191, 43.679291] },
  { cc: 'IE', name: 'Ireland', position: [53.41291, -8.24389] },
  { cc: 'IL', name: 'Israel', position: [31.046051, 34.851612] },
  { cc: 'IT', name: 'Italy', position: [41.87194, 12.56738] },
  { cc: 'CI', name: 'Ivory Coast', position: [7.539989, -5.54708] },
  { cc: 'JM', name: 'Jamaica', position: [18.109581, -77.297508] },
  { cc: 'JP', name: 'Japan', position: [36.204824, 138.252924] },
  { cc: 'JO', name: 'Jordan', position: [30.585164, 36.238414] },
  { cc: 'KZ', name: 'Kazakhstan', position: [48.019573, 66.923684] },
  { cc: 'KE', name: 'Kenya', position: [-0.023559, 37.906193] },
  { cc: 'KI', name: 'Kiribati', position: [-3.370417, -168.734039] },
  { cc: 'XK', name: 'Kosovo', position: [42.602636, 20.902977] },
  { cc: 'KW', name: 'Kuwait', position: [29.31166, 47.481766] },
  { cc: 'KG', name: 'Kyrgyzstan', position: [41.20438, 74.766098] },
  { cc: 'LA', name: 'Laos', position: [19.85627, 102.495496] },
  { cc: 'LV', name: 'Latvia', position: [56.879635, 24.603189] },
  { cc: 'LB', name: 'Lebanon', position: [33.854721, 35.862285] },
  { cc: 'LS', name: 'Lesotho', position: [-29.609988, 28.233608] },
  { cc: 'LR', name: 'Liberia', position: [6.428055, -9.429499] },
  { cc: 'LY', name: 'Libya', position: [26.3351, 17.228331] },
  { cc: 'LI', name: 'Liechtenstein', position: [47.166, 9.555373] },
  { cc: 'LT', name: 'Lithuania', position: [55.169438, 23.881275] },
  { cc: 'LU', name: 'Luxembourg', position: [49.815273, 6.129583] },
  { cc: 'MK', name: 'North Macedonia', position: [41.608635, 21.745275] },
  { cc: 'MG', name: 'Madagascar', position: [-18.87919, 47.507905] },
  { cc: 'MW', name: 'Malawi', position: [-13.254308, 34.301525] },
  { cc: 'MY', name: 'Malaysia', position: [3.14, 101.68] },
  { cc: 'MV', name: 'Maldives', position: [3.202778, 73.22068] },
  { cc: 'ML', name: 'Mali', position: [17.570692, -3.996166] },
  { cc: 'MT', name: 'Malta', position: [35.937496, 14.375416] },
  { cc: 'MH', name: 'Marshall Islands', position: [7.131474, 171.184478] },
  { cc: 'MR', name: 'Mauritania', position: [21.00789, -10.940835] },
  { cc: 'MU', name: 'Mauritius', position: [-20.348404, 57.552152] },
  { cc: 'MX', name: 'Mexico', position: [23.634501, -102.552784] },
  { cc: 'FM', name: 'Micronesia', position: [7.425554, 150.550812] },
  { cc: 'MD', name: 'Moldova', position: [47.411631, 28.369885] },
  { cc: 'MC', name: 'Monaco', position: [43.750298, 7.412841] },
  { cc: 'MN', name: 'Mongolia', position: [46.862496, 103.846656] },
  { cc: 'ME', name: 'Montenegro', position: [42.708678, 19.37439] },
  { cc: 'MA', name: 'Morocco', position: [31.791702, -7.09262] },
  { cc: 'MZ', name: 'Mozambique', position: [-18.665695, 35.529562] },
  { cc: 'MM', name: 'Myanmar', position: [21.913965, 95.956223] },
  { cc: 'NA', name: 'Namibia', position: [-22.559722, 17.08323] },
  { cc: 'NR', name: 'Nauru', position: [-0.522778, 166.931503] },
  { cc: 'NP', name: 'Nepal', position: [28.394857, 84.124008] },
  { cc: 'NL', name: 'Netherlands', position: [52.377956, 4.900279] },
  { cc: 'NC', name: 'New Caledonia', position: [-20.904305, 165.618042] },
  { cc: 'NZ', name: 'New Zealand', position: [-40.900557, 174.885971] },
  { cc: 'NI', name: 'Nicaragua', position: [12.865416, -85.207229] },
  { cc: 'NE', name: 'Niger', position: [17.607789, 8.081666] },
  { cc: 'NG', name: 'Nigeria', position: [9.081999, 8.675277] },
  { cc: 'KP', name: 'North Korea', position: [40.339852, 127.510093] },
  { cc: 'NO', name: 'Norway', position: [60.472024, 8.468946] },
  { cc: 'OM', name: 'Oman', position: [21.512583, 55.923255] },
  { cc: 'PK', name: 'Pakistan', position: [30.375321, 69.345116] },
  { cc: 'PW', name: 'Palau', position: [7.51498, 134.58252] },
  { cc: 'PS', name: 'Palestine', position: [31.952162, 35.233154] },
  { cc: 'PA', name: 'Panama', position: [8.538, -80.782127] },
  { cc: 'PG', name: 'Papua New Guinea', position: [-6.314993, 143.95555] },
  { cc: 'PY', name: 'Paraguay', position: [-23.442503, -58.443832] },
  { cc: 'PE', name: 'Peru', position: [-9.189967, -75.015152] },
  { cc: 'PH', name: 'Philippines', position: [12.879721, 121.774017] },
  { cc: 'PL', name: 'Poland', position: [51.919438, 19.145136] },
  { cc: 'PT', name: 'Portugal', position: [39.399872, -8.224454] },
  { cc: 'QA', name: 'Qatar', position: [25.354826, 51.183884] },
  { cc: 'CG', name: 'Republic of the Congo', position: [-0.228021, 15.827659] },
  { cc: 'RO', name: 'Romania', position: [45.943161, 24.96676] },
  { cc: 'RU', name: 'Russia', position: [61.52401, 105.318756] },
  { cc: 'RW', name: 'Rwanda', position: [-1.940278, 29.873888] },
  { cc: 'KN', name: 'Saint Kitts and Nevis', position: [17.357822, -62.782998] },
  { cc: 'LC', name: 'Saint Lucia', position: [13.909444, -60.978893] },
  { cc: 'VC', name: 'Saint Vincent and the Grenadines', position: [12.984305, -61.287228] },
  { cc: 'WS', name: 'Samoa', position: [-13.759029, -172.104629] },
  { cc: 'SM', name: 'San Marino', position: [43.94236, 12.457777] },
  { cc: 'ST', name: 'Sao Tome and Principe', position: [0.18636, 6.613081] },
  { cc: 'SA', name: 'Saudi Arabia', position: [23.885942, 45.079162] },
  { cc: 'SN', name: 'Senegal', position: [14.497401, -14.452362] },
  { cc: 'RS', name: 'Serbia', position: [44.016521, 21.005859] },
  { cc: 'SC', name: 'Seychelles', position: [-4.679574, 55.491977] },
  { cc: 'SL', name: 'Sierra Leone', position: [8.460555, -11.779889] },
  { cc: 'SG', name: 'Singapore', position: [1.352083, 103.819836] },
  { cc: 'SK', name: 'Slovakia', position: [48.669026, 19.699024] },
  { cc: 'SI', name: 'Slovenia', position: [46.151241, 14.995463] },
  { cc: 'SB', name: 'Solomon Islands', position: [-9.64571, 160.156194] },
  { cc: 'SO', name: 'Somalia', position: [5.152149, 46.199616] },
  { cc: 'ZA', name: 'South Africa', position: [-30.559482, 22.937506] },
  { cc: 'SS', name: 'South Sudan', position: [7.862684, 29.694923] },
  { cc: 'ES', name: 'Spain', position: [40.463667, -3.74922] },
  { cc: 'LK', name: 'Sri Lanka', position: [7.873054, 80.771797] },
  { cc: 'SD', name: 'Sudan', position: [12.862807, 30.217636] },
  { cc: 'SR', name: 'Suriname', position: [3.919305, -56.027783] },
  { cc: 'SZ', name: 'Eswatini', position: [-26.522503, 31.465866] },
  { cc: 'SE', name: 'Sweden', position: [60.128161, 18.643501] },
  { cc: 'CH', name: 'Switzerland', position: [46.818188, 8.227512] },
  { cc: 'SY', name: 'Syria', position: [34.802075, 38.996815] },
  { cc: 'TW', name: 'Taiwan', position: [23.69781, 120.960515] },
  { cc: 'TJ', name: 'Tajikistan', position: [38.861034, 71.276093] },
  { cc: 'TZ', name: 'Tanzania', position: [-6.369028, 34.888822] },
  { cc: 'TH', name: 'Thailand', position: [15.870032, 100.992541] },
  { cc: 'TL', name: 'Timor-Leste', position: [-8.874217, 125.727539] },
  { cc: 'TG', name: 'Togo', position: [8.619543, 0.824782] },
  { cc: 'TO', name: 'Tonga', position: [-21.178986, -175.198242] },
  { cc: 'TT', name: 'Trinidad and Tobago', position: [10.691803, -61.222503] },
  { cc: 'TN', name: 'Tunisia', position: [33.886917, 9.537499] },
  { cc: 'TR', name: 'Turkey', position: [38.963745, 35.243322] },
  { cc: 'TM', name: 'Turkmenistan', position: [38.969719, 59.556278] },
  { cc: 'TV', name: 'Tuvalu', position: [-7.478335, 178.67992] },
  { cc: 'UG', name: 'Uganda', position: [1.373333, 32.290275] },
  { cc: 'UA', name: 'Ukraine', position: [48.379433, 31.16558] },
  { cc: 'AE', name: 'United Arab Emirates', position: [23.424076, 53.847818] },
  { cc: 'GB', name: 'United Kingdom', position: [52.355518, -1.17432] },
  { cc: 'US', name: 'United States', position: [37.09024, -95.712891] },
  { cc: 'UY', name: 'Uruguay', position: [-32.522779, -55.765835] },
  { cc: 'UZ', name: 'Uzbekistan', position: [41.377491, 64.585262] },
  { cc: 'VU', name: 'Vanuatu', position: [-15.376706, 166.959158] },
  { cc: 'VE', name: 'Venezuela', position: [6.42375, -66.58973] },
  { cc: 'VN', name: 'Vietnam', position: [14.058324, 108.277199] },
  { cc: 'YE', name: 'Yemen', position: [15.552727, 48.516388] },
  { cc: 'ZM', name: 'Zambia', position: [-13.133897, 27.849332] },
  { cc: 'ZW', name: 'Zimbabwe', position: [-19.015438, 29.154857] },
  { cc: 'KR', name: 'South Korea', position: [37.5665, 126.9780] },
  { cc: 'IM', name: 'Isle of Man', position: [54.2361, -4.5481] },
  { cc: 'KY', name: 'Cayman Islands', position: [19.3133, -81.2546] },
  { cc: 'GG', name: 'Guernsey', position: [49.4482, -2.5895] },
  { cc: 'RE', name: 'Réunion', position: [-21.1151, 55.5364] },
  { cc: 'HK', name: 'Hong Kong', position: [22.3193, 114.1694] },
  { cc: 'PR', name: 'Puerto Rico', position: [18.2208, -66.5901] }
  ];

  export default countries;
  